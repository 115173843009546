import topcorner from './top-corner.png';
import secondfirst from './second-first.png';
import secondsecond from './second-second.png';
import secondthird from './second-third.png';
import './App.css';

function App() {
  return (
    <div className="container-fluid">
        <div className={"row"} id={"first-row"}>
          <div className={"col-5 col-xs-5 col-md-3"} id={"top-corner"}><img src={topcorner} alt={""}/> </div>
          <div className={"col-7 col-xs-7 col-md-9"} id={"household-label"}>household</div>
        </div>
        <div className={"row"}>
            <div className={"col-12 col-xs-12 col-md-8"}>
                <div className={"row"}>
                    <div className={"col-4 second-row-cell"}>
                        <img src={secondfirst} alt={""}/>
                    </div>
                    <div className={"col-4 second-row-cell"}>
                        <img src={secondsecond} alt={""}/>
                    </div>
                    <div className={"col-4 second-row-cell"}>
                        <img src={secondthird} alt={""}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-4 second-row-cell"}>
                        <img src={secondfirst} alt={""}/>
                    </div>
                    <div className={"col-4 second-row-cell"} id={"social-hq"}>
                        Your family's social HQ
                    </div>
                    <div className={"col-4 second-row-cell"}>
                        <img src={topcorner} alt={""}/>
                    </div>
                </div>
            </div>

            <div className={"col-12 col-xs-12 col-md-4"}>
                <div id={"coming-soon"}>
                    Coming soon!
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
